import { module as Md } from 'modujs';
import { cl, Observer } from '@/scripts/utils';

export default class extends Md {
  observer: Observer;
  constructor(m: Md) {
    super(m);

    this.observer = new Observer({
      rootMargin: '-1px 0px 0px 0px',
      threshold: 1,
      callback: (visible, el) => {
        const { bottom } = el.getBoundingClientRect();
        if (!visible && bottom < window.innerHeight) {
          cl(this.el).add('is-sticky');
        } else {
          cl(this.el).remove('is-sticky');
        }
      },
    });
  }

  init() {
    if (this.observer) {
      this.observer.add(this.el);
    }
  }

  destroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
