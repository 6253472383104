import { module as Md } from 'modujs';
import { cl } from '@/scripts/utils';
import select from 'dom-select';

export default class extends Md {
  isOpen = false;
  duration = 700;
  content: HTMLElement | null = null;
  inner: HTMLElement | null = null;
  name = '';

  constructor(m: Md) {
    super(m);

    this.events = {
      click: {
        trigger: 'toggle',
      },
    };
  }

  init() {
    this.isOpen = this.el.classList.contains('accordion-open');
    this.duration = Number(this.getData('duration') || '700');
    this.content = this.$('content')[0] || null;
    this.inner = this.$('inner')[0] || null;
    this.name = this.getData('name') || '';
    this.outerTriggers = this.name ? select.all(`[data-accordion-outer="${this.name}"]`) : [];

    this.outerTriggers.forEach((outer: HTMLDivElement) => {
      outer.addEventListener('click', () => {
        this.toggle();
        window.scrollTo(0, 0);
      })
    })
  }

  open() {
    this.closeAll();
    if (!this.isOpen && this.content) {
      this.content.style.height = this.getHeight();
      requestAnimationFrame(() => {
        cl(this.el).add('accordion-open');
        cl(this.outerTriggers).add('is-active')
        setTimeout(() => {
          if (this.content) {
            this.content.style.height = '';
          }
          this.isOpen = true;
        }, this.duration);
      });
    }
  }

  closeAll() {
    this.call('close', null, 'accordion');
  }

  close() {
    if (this.isOpen && this.content) {
      this.content.style.height = this.getHeight();
      requestAnimationFrame(() => {
        cl(this.el).remove('accordion-open');
        cl(this.outerTriggers).remove('is-active')
        setTimeout(() => {
          if (this.content) {
            this.content.style.height = '';
          }
          this.isOpen = false;
        }, this.duration);
      });
    }
  }

  openByName(name: string) {
    if (this.name === name) this.open();
  }

  toggle() {
    if (this.isOpen) this.close();
    else this.open();
  }

  getHeight() {
    return this.inner?.getBoundingClientRect().height + 'px' || '0px';
  }
}
