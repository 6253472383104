import { module as Md } from 'modujs';
import type { Swiper } from 'swiper/types'
import select from 'dom-select';

import { breakpointCallback, cl } from '@/scripts/utils';

export default class extends Md {
  swiper: Swiper|null = null;
  nextEl: HTMLDivElement|null = null;
  prevEl: HTMLDivElement|null = null;
  pagination: HTMLElement|null = null;
  textCarousel = false;
  captions: HTMLElement[] = [];

  loop = false;
  auto = false;
  fade = false;
  once = false;

  paused = false;
  timeout: any

  constructor(m: any) {
    super(m);

    this.loop = this.getData('loop') !== null ? !!this.getData('loop') : true;
    this.fade = this.getData('fade') !== null ? !!this.getData('fade') : false;
    this.auto = this.getData('auto') === '' || false;
    this.gap = this.getData('gap') === '' || false;

    this.pagination = this.$('pagination')[0] || null;
    this.textCarousel = Boolean(this.getData('text-carousel'));

    this.prevEl = this.$<HTMLDivElement>('prev')[0] || null;
    this.nextEl = this.$<HTMLDivElement>('next')[0] || null;

    this.captions = this.textCarousel
      ? select.all('.paragraph', this.el.closest('.block-image-text') as HTMLDivElement)
      : [];
  }

  init() {
    import('@/scripts/utils/swiper').then(({ Swiper: Sw, modules }) => {
      let spaceBetween = 20;
      if (this.gap) {
        spaceBetween = (this.el.getBoundingClientRect().width / 17) * 2 - 20
      }

      let vertical = false;
      if (this.getData('news') === '') {
        breakpointCallback('md', ({ matches }) => {
          vertical = matches;
        });
      }

      this.swiper = new Sw(select('.swiper-container', this.el), {
        modules,
        speed: 1300,
        effect: this.fade ? 'fade' : 'slide',
        loop: this.loop,
        direction: vertical ? 'vertical' : 'horizontal',
        touchStartPreventDefault: false,
        allowTouchMove: true,
        preventInteractionOnTransition: true,
        preventClicks: true,
        navigation: { nextEl: this.nextEl, prevEl: this.prevEl },
        spaceBetween,
        watchSlidesProgress: true,
        autoplay: vertical ? {
          pauseOnMouseEnter: true
        } : false,
        breakpoints: {
          768: {
            slidesPerView: Number(this.getData('slides') ? 2 : 1 || 1)
          },
          1024: {
            slidesPerView: Number(this.getData('slides') || 1)
          }
        },
        pagination: {
          el: this.pagination,
          type: "fraction",
          renderFraction: (currentClass, totalClass) => {
            return '<span class="' + currentClass + '"></span>' +
              ' of ' +
              '<span class="' + totalClass + '"></span>';
          },
        },
        on: {
          init: (swiper) => {
            if (this.getData('news') === '') {
              breakpointCallback('md', ({ matches }) => {
                // if (swiper) {
                //   swiper.changeDirection(matches ? 'vertical' : 'horizontal', true);
                // }
              });
            }
          },
          slideChange: (swiper) => {
            if (!this.once) {
              this.once = true;
              this.call('forceLoadImages', select.all('img', this.el), 'global');
            }
            if (this.captions.length) {
              cl(Array.from(this.captions)).remove('is-active');
              this.captions[swiper.realIndex].classList.add('is-active');
            }
          },
        }
      });
    })
  }

  destroy() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
