import { module as Md } from 'modujs';
import debounce from 'lodash.debounce';
import { cl, getHtmlFromUrl } from '@/scripts/utils';
import select from 'dom-select';

export default class extends Md {
  constructor(m: Md) {
    super(m);
    this.searchEl = this.$<HTMLInputElement>('search')[0] || null;
    this.resultsEl = this.$<HTMLInputElement>('results')[0] || null;
    this.pattern = this.el.closest('.pattern-component');
    this.snake = select('.pattern-snake', this.pattern);

    this.events = {
      input: {
        search: 'debouncedSearch',
      },
    };
  }

  init() {
    if (this.searchEl) {
      this.searchEl.focus();
    }
  }

  search(ev?: InputEvent) {
    const value = (ev?.target as HTMLInputElement).value;

    this.call('push', `${window.location.pathname}?q=${value}`, 'barba');

    getHtmlFromUrl(`/search/q-${value || 'null'}`, '.search-results').then((results) => {
      if (this.resultsEl && results) {
        this.resultsEl.innerHTML = results.innerHTML;

        const h = this.snake?.getBoundingClientRect().height || 0;
        cl(this.pattern).add('for-search');
        if (h > 0) {
          this.snake.style.height = `${h}px`;
        }

        this.call('scrollTop', null, 'lenis');
        this.call('update', this.el, 'modal');
      }
    });
  }

  debouncedSearch = debounce(this.search.bind(this), 300);
}
