import { module as Md } from 'modujs';
import { breakpoint, cl, gsap } from '@/scripts/utils';

export default class extends Md {
  masonry: any = null;
  constructor(m: Md) {
    super(m);
  }

  init() {
    import('masonry-layout').then(({ default: Masonry }) => {
      const init = () => {
        this.masonry = new Masonry(this.el, {
          itemSelector: '.grid-item',
          // columnWidth: '.grid-sizer',
          percentPosition: true,
          horizontalOrder: false,
        });
        this.masonry.on('layoutComplete', () => {
          cl(this.el).remove('opacity-0');
        });
        setTimeout(() => this.masonry.layout(), 0);
        setTimeout(() => this.masonry.layout(), 100);
      };

      gsap?.matchMedia().add(breakpoint('md'), () => {
        init();

        return () => {
          this.destroy();
        };
      })

      gsap?.matchMedia().add(breakpoint('to-md'), () => {
        cl(this.el).remove('opacity-0');
      });
    }).catch();
  }

  destroy() {
    if (this.masonry) {
      this.masonry.destroy();
    }
  }
}
