import { module as Md } from 'modujs';

export default class extends Md {
  constructor(m: Md) {
    super(m);
  }

  init() {
    const keys = [
      { transform: 'translateY(0%)' },
      { transform: 'translateY(0%)' },
      ...Array.from(this.el.children)
        .map((_, i, children) => {
          return [
            { transform: `translateY(-${(100 / children.length) * (i + 1)}%)` },
            { transform: `translateY(-${(100 / children.length) * (i + 1)}%)` },
          ];
        })
        .flat(1),
    ].slice(0, -2);
    this.el.animate(keys, {
      duration: 9000,
      iterations: Infinity,
    });
  }
}
