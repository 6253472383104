import { module as Md } from 'modujs';
import select from 'dom-select';
import { cl, ScrollTrigger, isBreakpoint } from '@/scripts/utils';

export default class extends Md {
  opened = false;

  constructor(m: Md) {
    super(m);
    this.titleEl = this.$('title', this.el.parentNode as HTMLElement) || [];
    this.title = this.titleEl.length ? this.getData('title', this.titleEl[0]) : '';
    this.filtersEl = this.$('el')[0] || null;
    this.trigger = this.getData('trigger') || null;
    this.multiSelect = !!this.getData('multi');
    this.noTransition = this.getData('notransition') === '';
    this.filtersStickyEl = select('.filters-sticky-el', this.el.parentNode as HTMLElement);

    this.events = {
      click: {
        toggle: 'toggle',
        hover: 'filterClick',
        close: 'close',
      },
      mouseenter: {
        hover: 'hover',
      },
      mouseleave: {
        hover: 'leave',
      },
    };
  }

  init() {
    this.opened = cl(this.el).has('filters-open');
    window.addEventListener('resize', this.resize.bind(this));
    this.resizeCheck = setInterval(this.resize.bind(this), 500);

    const catInfo = select('.category-info', this.el);

    if (!this.noTransition) {
      ScrollTrigger?.create({
        trigger: select(this.trigger) || this.el,
        start: 'top 20%',
        end: 'max',
        onToggle: (self) => {
          if (self.isActive && (
            catInfo && !cl(catInfo).has('is-active')
            || !catInfo
          )) {
            cl(this.el).add('filters-scrolled');
          } else {
            cl(this.el).remove('filters-scrolled');
          }
        },
      });
    }

    if (!this.filtersStickyEl) {
      ScrollTrigger?.create({
        trigger: select(this.trigger) || this.el,
        start: 'top 10%',
        end: 'max',
        onToggle: (self) => {
          if (self.isActive && (
            catInfo && !cl(catInfo).has('is-active')
            || !catInfo
          )) {
            cl(this.el).add('filters-fixed');
          } else {
            cl(this.el).remove('filters-fixed');
            this.close();
          }
        },
      });
    }

    ScrollTrigger?.refresh();
  }

  open() {
    this.call('closeMenu', null, 'menu');
    if (!this.opened) {
      this.opened = true;

      cl(this.el).add('filters-open');
      cl(document.body).add('filters-open');
    }
  }

  close() {
    if (this.opened) {
      this.opened = false;

      cl(this.el).remove('filters-open');
      cl(document.body).remove('filters-open');
    }
  }

  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  hover(ev: MouseEvent) {
    if (isBreakpoint('md')) {
      this.titleEl.forEach((titleEl: HTMLElement) => {
        if (titleEl) {
          cl(this.el).add('filters-hovered');
          cl(this.filtersStickyEl).add('filters-hovered');
          titleEl.innerHTML = select('span', ev.target as HTMLElement)?.innerHTML || '';
        }
      });
    }
  }

  filterClick(ev: MouseEvent) {
    const el = (ev.target as HTMLElement).closest('[data-filters-filter]');

    if (!el) return;

    const filter = this.getData('filter', el);
    const parts = filter?.split('=') || [];
    const url = new URL(window.location.href);
    if (this.multiSelect) {
      if (parts[1]) {
        url.searchParams.set(parts[0], parts[1]);
      } else {
        url.searchParams.delete(parts[0]);
      }
    } else {
      url.search = parts[1] ? `?${parts[0]}=${parts[1]}` : '';
    }
    this.call('go', { url: url.href, trigger: 'filter' }, 'barba');
    this.close();
  }

  leave() {
    this.titleEl.forEach((titleEl: HTMLElement) => {
      if (titleEl) {
        cl(this.el).remove('filters-hovered');
        titleEl.innerHTML = this.title;
      }
    });
  }

  resize() {
    if (!this.filtersEl) return;
    const h = this.filtersEl.getBoundingClientRect().height;

    clearInterval(this.resizeCheck);
    document.body.style.setProperty('--filters-height', h + 'px');
  }

  next(nextCont: HTMLDivElement) {
    const filters = select('.filters', nextCont);
    if (filters) {
      if (cl(this.el).has('filters-fixed')) {
        cl(filters).add('filters-fixed');
      }
    }
  }

  destroy() {
    // this.observer1?.disconnect();
    // this.observer2?.disconnect();
  }
}
