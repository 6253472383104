import { Observer, cl } from '.';

export default class LazyVideos {
  selector = '.lazy-video video';
  observer: Observer;

  constructor() {
    this.observer = new Observer({
      rootMargin: '0% 0% 0% 0%',
      threshold: 0,
      once: true,
      callback: (visible, el: HTMLVideoElement) => {
        if (visible && !cl(el).has('visible-once')) {
          this.load(el);
        }
      },
    });

    this.update(document);
  }

  load(el: any, canplay?: () => void) {
    el?.addEventListener('canplay', () => {
      cl(el).add('canplay');
      canplay?.();
    });
    for (let source in el?.children) {
      const videoSource = el.children[source] as HTMLSourceElement;
      if (videoSource.tagName === 'SOURCE') {
        videoSource.setAttribute('src', videoSource.dataset.src as string);
      }
    }

    el?.load();
  }

  update(scope: HTMLElement|Document) {
    const element: any = document.querySelector(this.selector);
    if (element) {
      element.forceLazyLoad = (canplay?: () => void) => {
        this.load(element, canplay);
      }
    }
    this.observer.add(this.selector, scope);
  }
}
