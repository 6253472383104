import { module as Md } from 'modujs';
import { getHtmlFromUrl } from '@/scripts/utils';
import Cookies from 'js-cookie';

export default class extends Md {
  url
  name
  cookie
  lock
  once
  toShow

  constructor(m: Md) {
    super(m);

    this.url = this.getData('url') || '';
    this.cookie = this.getData('cookie') || '';
    this.name = this.getData('name') || '';
    this.lock = this.getData('lock') !== (''||null);
    this.once = this.getData('once') !== (''||null);
    this.toShow = this.cookie && !Cookies.get(this.cookie) || !this.cookie;
  }

  // async getCloudflareJSON(): Promise<{ loc: string }> {
  //   let data = await fetch('https://1.1.1.1/cdn-cgi/trace').then(res=>res.text()).catch(() => '');
  //   let arr = data.trim().split('\n').map((e)=> e.split('='));
  //   return Object.fromEntries(arr);
  // }

  init() {
    if (
      this.el.innerHTML.length
      || (
        this.once
        && document.documentElement.classList.contains('doc-loaded')
      )
    ) return;

    if (this.url && this.toShow) {
      if (this.cookie) {
        Cookies.set(this.cookie, 'true', { expires: 1 });
        this.toShow = false;
      }

      if (this.lock) {
        this.call('lockScroll', null, 'lenis')
      }

      getHtmlFromUrl(this.url).then((doc) => {
        if (doc && !this.el.innerHTML.length) {
          this.el.innerHTML = doc.outerHTML;
          this.call('update', this.el, 'app');
          this.call('update', { container: this.el }, 'global');
          this.call('updateParallaxes', { container: this.el }, 'lenis');

        }
      })
    }
  }

  remove(name: string) {
    if (name === this.name) {
      this.el.remove();
    }
  }
}
