import { module as Md } from 'modujs';
import select from 'dom-select';
import { cl, LazyVideos } from '@/scripts/utils';
import LazyLoad from 'vanilla-lazyload';

export default class extends Md {
  cursor: HTMLDivElement|null = null
  cursorX: Function | undefined
  cursorY: Function | undefined

  constructor(m: Md) {
    super(m);

    this.cursor = this.$<HTMLDivElement>('cursor')[0] || null;
  }

  init() {
    this.sameLinkClick();
    this.setActiveLinks();

    this.videoObserver = new LazyVideos();

    // this.lazyload = new LazyLoad({
    //   threshold: window.innerHeight,
    //   unobserve_entered: true,
    //   callback_enter: (el) => {
    //     if (cl(el).has('lazyload-horizontal')) {
    //       const base = el.closest<HTMLDivElement>('.lazyload-horizontal-base');
    //       if (base) {
    //         this.forceLoadImages(select.all('img.lazy:not(.loaded)', base));
    //       }
    //     }
    //   },
    // });
    //
    // window.addEventListener('mousemove', this.moveCursor.bind(this))

    window.addEventListener('popstate', () => {
      this.call('update', document.body, 'modal');
    }, false);
  }

  sameLinkClick(parent?: HTMLElement) {
    select.all<HTMLAnchorElement>('a', parent).forEach((link) => {
      if (link.origin === window.location.origin) {
        link.addEventListener('click', (ev) => {
          if (link.href === window.location.href) {
            ev.preventDefault();
            ev.stopPropagation();

            this.call('closeMenu', null, 'menu');
            this.call('close', null, 'filters');
          }
        });
      }
    });
  }

  setActiveLinks(parent?: HTMLElement) {
    let hasActive = false;
    const links = select.all<HTMLAnchorElement>('.active-links', parent);

    links.forEach((link) => {
      link.blur();
      if (link.origin === window.location.origin) {
        let pageHref = window.location.href;
        const deep = link.getAttribute('data-deep') || null;
        if (deep) {
          const segments = window.location.pathname.substring(1).split('/');
          pageHref = window.location.origin + '/' + segments.slice(0, Number(deep)).join('/');
        }
        if (link.href === pageHref) {
          cl(link).add('active');
          if (cl(link).has('active-parent')) {
            cl(link.parentElement as HTMLDivElement).add('has-active');
            hasActive = true;
          }
        } else {
          cl(link).remove('active');
        }
      }
    });

    if (!hasActive) {
      links.forEach((link: HTMLAnchorElement) => {
        if (cl(link).has('active-parent')) {
          cl(link.parentElement as HTMLDivElement).remove('has-active');
        }
      });
    }
  }

  forceLoadImages(images = []) {
    images.forEach((img) => {
      LazyLoad.load(img, {});
      cl(img).add(['entered', 'loaded']);
    });
  }

  lazyLoad(img: HTMLImageElement) {
    if (img) {
      LazyLoad.load(img, {});
    }
  }

  lazyLoadUpdate() {
    // this.lazyload?.update();
  }

  lazyVideosUpdate(container: HTMLDivElement) {
    this.videoObserver?.update(container || document.body);
  }

  // moveCursor(ev: MouseEvent) {
  //   if (this.cursor && isDesktop() && cl(this.cursor).has('cursor-visible')) {
  //     this.cursorX?.(ev.clientX);
  //     this.cursorY?.(ev.clientY);
  //   }
  // }

  update(next: any) {
    this.lazyVideosUpdate(next.container);
    this.lazyLoadUpdate();
    this.sameLinkClick(next.container);
    this.setActiveLinks();

    this.call('update', next.container, 'lenis');
    this.call('update', next.container, 'modal');
  }
}
