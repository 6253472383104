import mitt from 'mitt';
import type { Emitter } from 'mitt';
import type { ITransitionData } from '@barba/core';

type Events = {
  barba_leave: ITransitionData;
  barba_enter: ITransitionData;
  busy: boolean;
  cancel: void;
  click: MouseEvent;
  keydown: KeyboardEvent;
  resize: void;
  worm_ready: string
};

export const bus: Emitter<Events> = mitt<Events>();
window.addEventListener('click', (ev) => {
  bus.emit('click', ev);
});

window.addEventListener('keydown', (ev) => {
  bus.emit('keydown', ev);
  if (ev.key === 'Escape') {
    bus.emit('cancel');
  }
});

let scheduledAnimationFrame = false;

function onResize() {
  if (scheduledAnimationFrame) {
    return;
  }

  scheduledAnimationFrame = true;
  requestAnimationFrame(() => {
    bus.emit('resize');
    scheduledAnimationFrame = false;
  });
}

window.addEventListener('resize', onResize);
