import { module as Md } from 'modujs';
import { breakpoint, cl, documentHeight, getScrollbarWidth, gsap, ScrollTrigger } from '@/scripts/utils';
import select from 'dom-select';

export default class extends Md {
  locked = false;

  constructor(m: Md) {
    super(m);
  }

  init() {
    import('@studio-freight/lenis').then(({ default: Lenis }) => {
      this.lenis = new Lenis({
        lerp: 0.1,
        smoothWheel: true,
      });

      if (this.locked) {
        this.lockScroll()
      }

      requestAnimationFrame(this.raf.bind(this))

      this.lenis.on('scroll', () => {
        cl(document.body).remove('hovered-color');
      });

      this.pageParallaxes();
      this.pageParallaxes(select('.footer'));

      document.querySelectorAll('a[href^="#"]').forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault()
          const id = el.getAttribute('href')?.slice(1)
          if (!id) return
          const target = document.getElementById(id)
          if (target) {
            this.lenis.scrollTo(target, {
              offset: 64,
              lock: true,
              duration: 4,
            })
          }
        })
      });
    }).catch();
  }

  raf(time: any) {
    this.lenis?.raf(time)
    requestAnimationFrame(this.raf.bind(this))
  }

  lockScroll() {
    this.locked = true;
    const scGap = getScrollbarWidth();

    if (scGap) {
      document.body.style.setProperty('--scroll-gap', scGap + 'px');
    }

    if (this.lenis) {
      this.lenis.stop();
    }

    this.updateParallaxes();
  }

  unlockScroll() {
    this.locked = false;
    document.body.style.removeProperty('--scroll-gap');
    if (this.lenis) {
      this.lenis.start();
    }

    this.updateParallaxes();
  }

  scrollTop() {
    if (this.lenis) {
      this.lenis.scrollTo(0, {
        duration: 0,
      });
    }
  }

  scrollTo(pos = 0) {
    if (this.lenis) {
      this.lenis.scrollTo(pos, {
        duration: 2,
      });
    }
  }

  pageParallaxes(container?: HTMLElement) {
    const homepageWorkSlideshow = select('.homepage-work-slideshow-container', container);

    if (homepageWorkSlideshow) {
      const piece1 = select('.piece-1', homepageWorkSlideshow);
      const piece2 = select('.piece-2', homepageWorkSlideshow);

      if (piece1 && piece2) {
        gsap
          ?.timeline({
            scrollTrigger: {
              trigger: homepageWorkSlideshow,
              start: 'top bottom',
              end: 'top top',
              scrub: true,
            },
          })
          .set(piece1, {
            y: '30%',
          })
          .to(piece1, {
            ease: 'none',
            y: '0',
          });

        gsap
          ?.timeline({
            scrollTrigger: {
              trigger: homepageWorkSlideshow,
              start: 'bottom bottom',
              end: 'bottom top',
              scrub: true,
            },
          })
          .set(piece2, {
            y: '0',
          })
          .to(piece2, {
            ease: 'none',
            y: '-30%',
          });
      }
    }

    select.all('.snake-parallax-container', container).forEach((parallaxContainer) => {
      select.all('.pattern-component', parallaxContainer).forEach((snake) => {
        if (snake) {
          gsap?.matchMedia().add(breakpoint('to-md'), () => {
            gsap
              ?.timeline({
                scrollTrigger: {
                  trigger: parallaxContainer,
                  start: 'top bottom',
                  end: 'bottom top',
                  scrub: true,
                },
              })
              .set(snake, {
                y: '0',
              })
              .to(snake, {
                ease: 'none',
                y: '30%',
              });
          });
          gsap?.matchMedia().add(breakpoint('md'), () => {
            gsap
              ?.timeline({
                scrollTrigger: {
                  trigger: parallaxContainer,
                  start: 'top bottom',
                  end: 'bottom top',
                  scrub: true,
                },
              })
              .set(snake, {
                y: '30%',
              })
              .to(snake, {
                ease: 'none',
                y: '-30%',
              });
          });
        }
      });
    });

    // if (!skipRefresh) {
    //   setTimeout(() => {
    //     this.gsap.add((_, scrollTrigger) => {
    //       scrollTrigger.refresh();
    //     })
    //   }, 3500);
    // }
  }

  updateParallaxes() {
    ScrollTrigger?.refresh();
    this.resize();
  }

  update(container: HTMLElement) {
    this.pageParallaxes(container);
  }

  resize() {
    this.lenis?.resize();
  }

  destroy() {
    if (this.lenis) {
      this.lenis.destroy();
      ScrollTrigger?.getAll().forEach((tr) => {
        tr.kill(true)
      });
    }
  }
}
