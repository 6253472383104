import Modular from 'modujs';
import * as modules from '@/scripts/modules';

import '../styles/index.css';

(function (doc) {
  const app = new Modular({ modules });
  function init() {
    app.init(app);

    setTimeout(() => {
      document.documentElement.classList.add('doc-loaded')
    }, 1000);
  }

  doc.addEventListener('DOMContentLoaded', init);
})(document);
