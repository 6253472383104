import { module as Md } from 'modujs';
import serialize from 'form-serialize';
import { cl } from '@/scripts/utils';
import debounce from 'lodash.debounce';
export default class extends Md {
  constructor(m: Md) {
    super(m);

    this.events = {
      submit: {
        form: 'submit'
      },
    }
  }

  debouncedValidate = debounce(this.validate, 500);

  init() {
    this.$('input').forEach((input) => {
      input.addEventListener('input', this.debouncedValidate.bind(this));
    })
  }

  validate() {
    const valid = Object.values(serialize(this.el, { hash: true, empty: true })).every((val) => !!val);

    if (valid) {
      cl(this.$('button')[0]).remove('invisible');
    } else {
      cl(this.$('button')[0]).add('invisible');
    }
  }

  submit(ev: SubmitEvent) {
    ev.preventDefault();
    ev.stopPropagation();

    const serialised = serialize(this.el, { hash: true })
    fetch('/subscribe/subscribe', {
      method: 'POST',
      body: JSON.stringify(serialised)
    })
      .then((resp) => resp.json())
      .then(() => {
        cl(this.el).add('hidden');
        setTimeout(() => {
          this.call('close', null, 'modal');
        }, 3000);
      })
  }
}
