import { module as Md } from 'modujs';
import { cl, getHtmlFromUrl, isBreakpoint } from '@/scripts/utils';
import select from 'dom-select';
import Cookies from 'js-cookie';
import { bus } from '@/scripts/bus.ts';

export default class extends Md {
  name: string = ''
  last = 7;
  currentNo = 0;
  loadingNext = false;
  skipReady = false;
  hasHoverImages = false;
  ready = false;
  cursor: HTMLDivElement|null = null;
  hoverResetTimeout: any = 0;
  coverVideo: any|null = null;
  coverVideoOnce = true;

  constructor(m: Md) {
    super(m);

    this.showOnce = this.getData('cookie') || false;
    this.name = this.getData('name') || '';
    this.shown = this.showOnce && !!Cookies.get(this.showOnce);
    this.pattern = this.$('pattern')[0] || null;
    this.inner = this.$('inner')[0] || null;
    this.isCoverPattern = cl(this.el).has('for-transition');
    this.isMenuPattern = !!this.el.closest('.menu-pattern');
    this.isColourful = !cl(this.el).has('has-color') && !cl(this.el).has('has-image');
    this.cursor = select<HTMLDivElement>('[data-global="cursor"]');
    this.hasHoverImages = !!this.getData('hover-images')
    this.skipReady = !!this.getData('skip-ready');

    if (this.skipReady) {
      bus.on('worm_ready', (name) => {
        if (this.name === name) {
          this.coverVideoOnce = true;
          this.setReady(true);
        }
      })
    }

    const current = this.getData('pattern') || 'default0';
    this.currentNo =
      window.currentPattern > -1 ? window.currentPattern : Number(current.replace('default', ''));

    this.events = {
      click: {
        // pattern: 'getNextPattern',
        el: 'clicked',
      },
    };

    if (this.hasHoverImages) {
      this.events = {
        ...this.events,
        mouseenter: {
          segment: 'segmentHover',
          gap: 'timerLeave',
        },
        mouseover: {
          segment: 'segmentHover'
        },
        mouseleave: {
          pattern: 'segmentsLeave'
        }
      }
    }
  }

  init() {
    if (this.showOnce) {
      if (this.shown && cl(document.body).has('first') && !cl(this.el).has('checked')) {
        cl(this.el).add('hidden');
      } else if (!this.shown) {
        Cookies.set(this.showOnce, 'true', { expires: 12 });
        cl(this.el).add('checked');
      }
    }

    if (cl(this.el).has('splash')) {
      cl(document.documentElement).remove('to-load');
      setTimeout(() => {
        cl('.splash-screen').add('is-ready');
      }, 100)
      setTimeout(() => {
        cl(document.documentElement).remove('homepage');
      }, 1500)

      setTimeout(() => {
        this.clicked();
      }, 7000);
    }

    window.addEventListener('resize', this.resize.bind(this));
    this.resizeCheck = setInterval(this.resize.bind(this), 100);

    const imageRatio = select('svg', this.el);
    const imageRatioHeight = imageRatio?.getBoundingClientRect().height || 0;
    if (imageRatioHeight > 0) {
      this.el.style.setProperty('--snake-ratio-height', Math.round(imageRatioHeight) + 'px');

      setTimeout(() => {
        this.el.style.removeProperty('--snake-ratio-height');
        this.el.style.removeProperty('--snake-height');
      }, 4000);
    }

    if (this.inner) {
      const innerH = this.inner.getBoundingClientRect().height;
      this.el.style.setProperty('--snake-height', Math.round(innerH) + 'px');
    }

    if (this.name === 'home-cover') {
      this.coverVideo = select<HTMLVideoElement>('video', this.el);
    }
  }

  resize() {
    let biggest = 0;
    Array.from(this.$('header-space')).forEach((el) => {
      const h = el.getBoundingClientRect().height;
      if (h > biggest) {
        biggest = h;
      }
    });
    if (biggest > 0) {
      clearInterval(this.resizeCheck);
      this.el.style.setProperty('--snake-header-height', Math.round(biggest) + 'px');
      document.body.style.setProperty('--snake-header-height', Math.round(biggest) + 'px');

      this.setReady();
    }
  }

  setReady(force = false) {
    if (!this.skipReady || force) {
      this.ready = true;
      cl(this.el).add('ready');
      if (!cl(document.body).has('in-transition')) {
        cl(this.el).add('ready-color');
      }

      if (this.coverVideo && this.coverVideoOnce && force) {
        this.resize();

        this.coverVideoOnce = false;
        setTimeout(() => {
          this.coverVideo?.forceLazyLoad(() => {
            cl(this.el).add('pattern-video-started');
          });
        }, 3000);

        this.coverVideo.addEventListener('ended', () => {
          cl(this.el).remove('pattern-video-started');
        })
      }
    }
  }

  async getNextPattern() {
    if (this.loadingNext) {
      return Promise.resolve(false);
    }

    this.loadingNext = true;

    if (this.currentNo < this.last) {
      this.currentNo++;
    } else {
      this.currentNo = 0;
    }
    window.currentPattern = this.currentNo;
    return getHtmlFromUrl(`/pattern-default${this.currentNo}`, '.pattern')
      .then((pattern) => {
        if (!pattern) {
          return;
        }

        this.pattern.innerHTML = pattern.innerHTML;
        this.loadingNext = false;
      })
      .then(() => {
        return true;
      });
  }

  doPatternChange(nextPattern?: HTMLElement) {
    const isMenuOpen = cl('.header').has('menu-opened');
    const isCoverPatternAnimation = cl('.header').has('cover-pattern');

    if (this.isColourful && !isMenuOpen) {
      return;
    }

    if (
      (isMenuOpen && isCoverPatternAnimation && this.isCoverPattern) ||
      (isMenuOpen && !isCoverPatternAnimation && this.isMenuPattern) ||
      (!isMenuOpen && this.isMenuPattern)
    ) {
      this.getNextPattern().then((changed) => {
        if (changed && nextPattern) {
          nextPattern.innerHTML = this.pattern.innerHTML;
        }
      });
    }
  }

  update({ trigger, next }: { trigger: any; next: HTMLElement }) {
    const nextPattern = select('.pattern-component.for-transition', next);
    const isMenuOpen = cl('.header').has('menu-opened');

    if (nextPattern) {
      this.getData('cookie', nextPattern);

      const showOnce = this.getData('cookie', nextPattern) || false;
      const shown = showOnce && !!Cookies.get(showOnce);

      if (shown) {
        cl(nextPattern).add('hidden');
      } else {
        const nextPatternEl = select('.pattern', nextPattern);

        if (
          isBreakpoint('to-md') ||
          (isBreakpoint('md') && !(typeof trigger === 'object' && trigger.dataset.menu)) ||
          (!isMenuOpen && this.isMenuPattern)
        ) {
          this.doPatternChange(nextPatternEl);
        } else if (nextPatternEl && this.isCoverPattern) {
          nextPatternEl.innerHTML = this.pattern.innerHTML;
        }
      }
    }
  }

  clicked() {
    if (cl(this.el).has('splash')) {
      cl(this.el).add('splash-closing')

      if (cl(document.body).has('page-homepage')) {
        document.body.style.removeProperty('--bg-color')
      }

      setTimeout(() => {
        this.call('remove', 'splash-screen', 'refetch');
        this.call('unlockScroll', null, 'lenis');

        bus.emit('worm_ready', 'home-cover');
      }, 1000)
    } else if (this.isCoverPattern) {
      if (window.location.pathname === '/') {
        const homepageTitle = select('.home-title');
        const homepageTitlePos = homepageTitle?.getBoundingClientRect().top || 0;
        if (homepageTitlePos) {
          this.call('scrollTo', (homepageTitlePos + window.scrollY) - 64, 'lenis');
        }
      } else {
        this.call('scrollTo', window.innerHeight - 64, 'lenis');
      }

    }
  }

  /*
   * hover over pattern add el class
   * hover over next add el class next
   */

  segmentHover(ev: MouseEvent) {
    if (this.hoverResetTimeout) {
      clearTimeout(this.hoverResetTimeout)
    }

    if (cl(this.el).has('segments-hovered')) {
      cl(this.el).add('segments-hovered-next');
    }

    requestAnimationFrame(() => {
      cl(this.el).add('segments-hovered');
    })

    const targetSegment = ev.target as HTMLDivElement;

    cl(Array.from(this.$<HTMLDivElement>('segment'))).remove('segment-hovered');
    cl(targetSegment).add('segment-hovered');

    // previous image always visible for nice transition
    cl(Array.from(this.$('hover-image'))).remove('prev-segment-hovered');
    cl('[data-snake="hover-image"].segment-hovered').add('prev-segment-hovered');

    requestAnimationFrame(() => {
      cl(Array.from(this.$('hover-image'))).remove('segment-hovered');
      const targetImage = this.$('hover-image')?.[Number(this.getData('segment', targetSegment))] || null;
      // new hovered image shown
      cl(targetImage).add('segment-hovered');
    })
  }

  segmentsLeave() {
    cl(Array.from(this.$<HTMLDivElement>('segment'))).remove('segment-hovered');
    cl(Array.from(this.$('hover-image'))).remove(['prev-segment-hovered', 'segment-hovered']);
    cl(this.el).remove(['segments-hovered', 'segments-hovered-next']);
  }

  timerLeave() {
    if (this.hoverResetTimeout) {
      clearTimeout(this.hoverResetTimeout)
    }

    this.hoverResetTimeout = setTimeout(() => {
      this.segmentsLeave()
    }, 2000);
  }
}
