import { module as Md } from 'modujs';
import select from 'dom-select';
import { cl, getHtmlFromUrl, Observer } from '@/scripts/utils';

export default class extends Md {
  nextLoaded = false;
  constructor(m: Md) {
    super(m);

    this.next = this.getData('next') || null;
    this.nextEl = this.$('trigger')[0] || null;
    this.observerEl = this.$('observer')[0] || null;
    this.body = this.$('body')[0] || null;
    this.auto = this.getData('auto') || false;

    this.events = {
      click: {
        trigger: 'getNextPage',
      },
    };

    if (this.observerEl) {
      this.observer = new Observer({
        rootMargin: '0px 0px 100% 0px',
        callback: (visible) => {
          if ((visible && this.nextLoaded) || (visible && this.auto)) {
            this.getNextPage();
          }
        },
      });
      this.observer.add(this.observerEl);
    }
  }

  getNextPage() {
    if (this.next) {
      getHtmlFromUrl(this.next, '[data-module-pagination]').then((html) => {
        if (!html) {
          return
        }

        this.next = this.getData('next', html) || null;
        const items = select.all('[data-pagination="item"]', html);

        if (this.body) {
          items.forEach((item) => {
            this.body.appendChild(item);
          });

          this.call('lazyVideosUpdate', this.body, 'global');
        }

        if (this.next) {
          this.nextLoaded = true;
        }

        cl(this.nextEl).add('hidden');
      });
    }
  }
}
