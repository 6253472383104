import { module as Md } from 'modujs';
import { cl, getHtmlFromUrl } from '@/scripts/utils';

export default class extends Md {
  constructor(m: Md) {
    super(m);
    this.content = this.$('content')?.[0] || null;
    this.isOpen = false;
    this.isLoading = false;

    this.events = {
      click: {
        close: 'close',
      },
    };
  }

  init() {
    this.update();
  }
  open(url: string, slug?: string) {
    if (!url || this.isLoading || this.isOpen) return;

    this.isLoading = true;
    cl(document.body).add('is-loading');
    getHtmlFromUrl(url, '.modal-content').then((html) => {
      if (html && this.content) {
        this.isOpen = true;
        this.content.innerHTML = '';
        this.content.appendChild(html);
        this.call('lazyVideosUpdate', null, 'global');
        this.call('lockScroll', null, 'lenis');
        cl(this.el).add('is-open');
        cl(document.body).add('is-open').remove('is-loading');
        this.isLoading = false;
        this.call('update', html, 'app');

        if (slug) {
          const url = new URL(window.location.href);
          url.searchParams.set('modal', slug);
          this.call('push', url.href, 'barba');
        }
      }
    });
  }

  close() {
    if (this.isOpen) {
      cl(this.el).remove('is-open');
      cl(document.body).remove('is-open');
      setTimeout(() => {
        this.call('unlockScroll', null, 'lenis');
        this.content.innerHTML = '';
        this.isOpen = false;
      }, 1000);

      const url = new URL(window.location.href);

      if (url.searchParams.get('modal')) {
        url.searchParams.delete('modal');
        this.call('push', url.href, 'barba');
        window.history.replaceState({}, '', url.href);
      }
    }
  }

  update(container = document.body, check = false) {
    if (!check) {
      this.$('trigger', container).forEach((trigger: HTMLElement) => {
        trigger.addEventListener('click', () => {
          this.open(this.getData('url', trigger) || '', this.getData('slug', trigger) || '');
        });
      });
    }

    const { searchParams } = new URL(window.location.href);
    if (searchParams.get('modal')) {
      const el = Array.from(this.$('trigger', document.body)).find((person) => {
        return this.getData('slug', person) === searchParams.get('modal');
      });
      if (el) {
        setTimeout(() => {
          this.open(this.getData('url', el) || '');
        }, 500);
      }
    } else {
      this.close();
    }
  }
}
