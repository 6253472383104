import { module as Md } from 'modujs';
import { checkForCoverPattern, cl, gsap, isBreakpoint } from '@/scripts/utils';
import select from 'dom-select';

export default class extends Md {
  isOpen = false;
  inProgress = false;
  busy = false;
  openedWithCoverPattern = false;
  currentHover: string | null = null;
  constructor(m: Md) {
    super(m);

    this.menu = this.$('menu')[0] || null;

    this.events = {
      click: {
        burger: 'toggleMenu',
        closeFilters: 'closeFilters',
      },
      mouseenter: {
        'link-hover': 'hoverLink',
      },
      mouseleave: {
        'link-leave': 'hoverLeave',
      },
    };
  }

  init() {
    this.on('busy', 'barba', (ev) => {
      this.busy = ev.detail.busy;
    });

    // this.resize();
    // window.addEventListener('resize', this.resize.bind(this));
    window.addEventListener('keydown', this.esc.bind(this));
  }

  openMenu() {
    if (this.isOpen || this.inProgress) return;
    this.inProgress = true;

    this.call('lockScroll', null, 'lenis');
    this.call('close', null, 'filters');

    this.pattern = select('.pattern-component.for-transition');
    this.openedWithCoverPattern = this.pattern && checkForCoverPattern(this.pattern);

    new Promise((resolve) => {
      if (this.openedWithCoverPattern) {
        cl(this.pattern).add('menu-animating');
        cl(this.el).add(['menu-opened', 'menu-opened-post']);
        if (!cl(this.pattern).has('hidden')) {
          cl(this.el).add('cover-pattern');
        }

        this.patternEl = select('.pattern', this.pattern);
        const patternElTop = this.patternEl?.getBoundingClientRect().top || 0;
        const menuBottom = this.menu?.getBoundingClientRect().bottom || 0;

        gsap?.to(this.patternEl, {
          y: menuBottom - patternElTop,
          duration: 0.7,
          ease: 'power3.out',
          onComplete: resolve,
        });
      } else {
        cl(this.el).add(['menu-opened', 'menu-opened-post']);
        setTimeout(resolve, 500);
      }
    }).then(() => {
      this.isOpen = true;
      this.inProgress = false;
    });
  }

  closeMenu(off?: boolean) {
    if (!this.isOpen || this.inProgress) return;

    this.inProgress = true;
    cl(this.el).remove('menu-opened');

    new Promise<void>((resolve) => {
      if (this.openedWithCoverPattern && this.patternEl) {
        const menuBottom = this.menu?.getBoundingClientRect().bottom || 0;
        const headerHeight = select('.header')?.getBoundingClientRect().height || 0;
        const toSet = menuBottom - headerHeight + (isBreakpoint('lg') ? 10 : 0);

        if (cl(document.body).has('menu-transition')) {
          gsap?.set(this.patternEl, {
            y: toSet,
          });
        }

        gsap?.to(this.patternEl, {
          y: off ? toSet + window.innerHeight - menuBottom : 0,
          duration: 0.7,
          delay: 0.4,
          ease: 'power3.out',
          onComplete: resolve,
        });
      } else {
        setTimeout(resolve, 1000);
      }
    }).then(() => {
      this.call('unlockScroll', null, 'lenis');
      cl(this.pattern).remove('menu-animating');
      cl(this.el).remove(['cover-pattern', 'menu-opened-post']);

      this.inProgress = false;
      this.isOpen = false;
    });
  }

  esc(ev: KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.closeMenu();
      this.closeFilters();
      this.call('close', null, 'modal')
    }
  }

  toggleMenu() {
    if (this.isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  closeFilters() {
    this.call('close', null, 'filters');
  }

  hoverLink(ev: MouseEvent) {
    if (this.busy) return;

    const hovered = (ev.target as HTMLAnchorElement).href;

    const color = this.getData('color', ev.target as HTMLElement) || '#fff';
    const pColor = this.getData('pcolor', ev.target as HTMLElement) || '#ECECEC';
    if (!cl(document.body).has('has-color')) {
      if (color === '#fff') {
        cl(document.body).remove('hovered-color');
      } else {
        cl(document.body).add('hovered-color');
      }
    } else {
      cl(document.body).add('hovered-color');
    }

    if (this.currentHover !== hovered && isBreakpoint('md')) {
      this.call('doPatternChange', null, 'snake');
    }

    this.currentHover = hovered;

    document.body.style.setProperty('--hovered-bg-color', color);
    document.body.style.setProperty('--hovered-p-color', pColor);
  }

  hoverLeave() {
    cl(document.body).remove('hovered-color');
    document.body.style.removeProperty('--hovered-bg-color');
    document.body.style.removeProperty('--hovered-p-color');
  }

  resize() {
    if (this.menu) {
      const h = this.menu.getBoundingClientRect().height;
      document.body.style.setProperty('--menu-height', `${h}px`);
    }
  }
}
