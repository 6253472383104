import { cl } from './index';
import select from 'dom-select';

interface Options {
  rootMargin?: string;
  threshold?: number;
  class?: string;
  app?: any;
  once?: boolean;
  callback?: (visible?: boolean, target?: any, entry?: any) => void;
}
export class Observer {
  options: Options;
  app: any;
  observer: IntersectionObserver;

  constructor(options: Options) {
    this.options = options;
    this.observer = new IntersectionObserver(this.onIntersection.bind(this), {
      rootMargin: options.rootMargin || '0% 0% -30% 0%',
      threshold: options.threshold || 0,
    });
    this.app = options.app || null;

    return this;
  }

  add(selector: string | HTMLElement, parent?: HTMLElement|Document) {
    this.disconnect();
    if (typeof selector === 'string') {
      select.all(selector, parent as any).forEach((el: HTMLElement) => {
        this.observer.observe(el);
      });
    } else {
      this.observer.observe(selector);
    }
  }

  onIntersection(entries: IntersectionObserverEntry[]) {
    entries.map((entry) => {
      const target = entry.target as HTMLDivElement;
      if (entry.isIntersecting) {
        if (target.dataset.run && !cl(target).has('visible-once')) {
          const args = target.dataset.run.split(',').map((arg) => arg.trim());
          if (args.length > 2) {
            this.app.call(args[1], { el: target, param: args[2] }, args[0]);
          }
        }
        if (this.options?.class) {
          cl(target).add(this.options?.class);
        }
        if (this.options?.callback) {
          this.options?.callback(true, target, entry);
        }
        cl(target).add('visible').add('visible-once');
      } else {
        cl(target).remove('visible');
        if (this.options?.class) {
          cl(target).remove(this.options?.class);
        }
        if (this.options?.callback) {
          this.options?.callback(false, target, entry);
        }
      }
    });
  }

  disconnect() {
    this.observer.disconnect();
  }
}
