import { module as Md } from 'modujs';
import type Plyr from 'plyr';

export default class extends Md {
  player: Plyr | null;
  constructor(m: Md) {
    super(m);

    this.player = null;
  }

  init() {
    import('plyr').then(({ default: Plyr }) => {
      this.player = new Plyr(this.el, {
        controls: ['play-large', 'play', 'progress', 'mute', 'fullscreen'],
        hideControls: false,
        resetOnEnd: true,
        tooltips: { controls: false, seek: false },
      });

      if (this.player) {
        this.player.toggleControls(false);
        this.player.on('play', () => {
          this.player?.toggleControls(true);
        });
      }
    }).catch();
  }
  destroy() {
    if (this.player) {
      this.player.destroy();
    }
  }
}
