import { module as Md } from 'modujs';
import { cl, getHtmlFromUrl } from '@/scripts/utils';

export default class extends Md {
  constructor(m: Md) {
    super(m);
    this.content = this.$('content')?.[0] || null;
    this.hovered = 0;

    this.events = {
      mouseenter: {
        hover: 'hover',
      },
      mouseleave: {
        leave: 'leave',
      },
    };

    this.images = new Map<string, string>();
  }

  hover(ev: MouseEvent) {
    const id = this.getData('id', ev.target as HTMLElement);
    if (id) {
      const image = this.images.get(id);
      this.hovered = parseInt(id);

      if (!image) {
        this.images.set(id, 'loading');
        getHtmlFromUrl(`/people/image/${id}`, '.person-image').then((html) => {
          if (html) {
            if (this.hovered === parseInt(id) && this.content) {
              this.content.innerHTML = '';
              this.content.appendChild(html);
            }
            this.images.set(id, html);
          } else {
            this.content.innerHTML = '';
            this.images.delete(id);
          }
        });
      } else if (image !== 'loading' && this.content) {
        this.content.innerHTML = '';
        this.content.appendChild(image);
      }
    }
  }

  leave() {
    this.hovered = 0;
    if (this.content) {
      this.content.innerHTML = '';
    }
  }

  destroy() {
    this.leave();
    this.hovered = 0;
    this.images.clear();
  }
}
